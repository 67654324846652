import { Component, OnInit } from '@angular/core';

import { ApiService } from 'app/ts/ApiService';
import { Router } from '@angular/router';
import { OtpLogin, PasswordDetails, UserCredentials } from 'app/ts/models/user-credentials';
import { Authentication } from 'app/ts/models/broker-interfaces';

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { PendingApprovalLoansService } from 'app/ts/services/pending-approval-loans.service';
import { ProductSelectService } from 'app/ts/services/product-select.service';
import { MatDialog } from '@angular/material/dialog';
import { OtpComponent } from '../otp/otp.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  passwordDetails: PasswordDetails;
  showDetails: boolean;
  credentials = new OtpLogin();
  authResponse: number;
  error:string;
  constructor(
    private api: ApiService,
    private router: Router,
    private snackbar: MatSnackBar,
    private _pendingLoansService: PendingApprovalLoansService,
    private _productSelectService: ProductSelectService,
    private dialog: MatDialog
  ) {
  }


  ngOnInit() {
    this.showDetails = false;
    this.getStaffId();
  }

  signIn(): void {
    this.api.generateLoginOtp(this.credentials).subscribe((data: any) => {
      var x = data;
      this.credentials.id = data;
      this.openDialog(data);
    })
  }

  getStaffId(): void {
    const currentURL = window.location.href;

// Extract the number from the URL using regular expressions
const match = currentURL.match(/\/(\d+)$/);

if (match && match[1]) {
  const extractedNumber = parseInt(match[1], 10); // Parse the number as an integer
  sessionStorage.setItem('clientId', extractedNumber.toString());
  console.log(`Extracted number: ${extractedNumber}`);
} else {
  console.log('Number not found in the URL.');
}
  }

  openDialog(id: number, invalid: boolean = false): void {
    const dialogRef = this.dialog.open(OtpComponent, {
      width: '350px',
      data: { otp: this.credentials.otp, OtpType: id, InvalidOtp: invalid, },
      disableClose: true
    });

    // dialogRef.componentInstance.submitResendOTP.subscribe((res) => {
    //   this.api.generateLoginOtp(this.credentials).subscribe((data: any) => {
    //     var x = data;
    //     this.credentials.id = data;
    //     this.openDialog(data);
    //   })
    // })

    // dialogRef.componentInstance.submitEmailOTP.subscribe((res) => {
    //   this.api.emailOtp(this.credentials).subscribe((data: AuthResponse) => {
    //     this.authResponse = data;
    //   })
    // })

    dialogRef.afterClosed().subscribe(result => {
      this.credentials.otp = result;
      //this.isSpinnerDisplayed = true;

      if (this.authResponse != 0) {

        this.api.validateLoginOtp(this.credentials).subscribe((data : any) => {
          if (data == true) {
            sessionStorage.setItem('authenticated', 'true');
            const currentDate = new Date();
            const futureDate = new Date(currentDate.getTime() + 15 * 60 * 1000);
            sessionStorage.setItem('sessionExpiresAt', futureDate.toString());
            this.router.navigate(['broker/client-capture/0'], { queryParams: { userId: data.userId } });
          }
          else {
            this.error = "Could not validate the OTP";
          }
        });

      }
    });
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
