<div class="row d-flex justify-content-center align-contents-center text-center">
  <div class="col-md-10">
    <div mat-dialog-content>
      <h1 mat-dialog-title>{{otpName}}</h1>
      <p class="small text-center" *ngIf="!showOtpError">{{otpDescription}}</p>
      <p *ngIf="showOtpError" class="small text-danger">You have entered an incorrect OTP. Please try again</p>
      <mat-form-field appearance="standard">
        <mat-label class="pl-4">Enter your OTP</mat-label>
        <input class="mat-input-element mat-form-field-autofill-control" matInput [(ngModel)]="data.otp">
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="mt-5 mb-2 justify-content-center">
      <button class="btn-primary" mat-button [mat-dialog-close]="data.otp"
        cdkFocusInitial>SUBMIT</button>
    </div>
    <!-- <div class="text-center">
      <span role="button" class="text-primary small" (click)="resendOTP()">Resend OTP</span>
      <br>
      <span role="button" class="text-primary small" (click)="emailOTP()">Email OTP</span>
    </div> -->
  </div>
</div>