<div class="container-fluid">
    <div class="card primary-card">
        <div>
            <div class="row p-4">
                <h3 class="card-title"><b>Upload</b> Documents</h3>
            </div>
            <mat-tab-group [(selectedIndex)]="selectedIndex" mat-stretch-tabs>
                <!-- <mat-tab #clientDetails [disabled]="!clientDetails.isActive" label="Client Details">
                    <app-personal-details (updateStepEvent)="updateStep()"></app-personal-details>
                </mat-tab>
                <mat-tab #bankEmployerDetails [disabled]="!bankEmployerDetails.isActive" label="Bank and Employer Details">
                    <app-bank-employer-details (updateStepEvent)="updateStep()" (eventChange)="onChange()">
                    </app-bank-employer-details>
                </mat-tab>
                <mat-tab *ngIf="showProductSelection == true" #creditCheck [disabled]="!creditCheck.isActive" label="Credit Check Result">
                    <app-credit-check (updateStepEvent)="updateStep()" [event]="clientId" (previousStepEvent)="previousStep()"></app-credit-check>
                </mat-tab>
                <mat-tab *ngIf="showProductSelection == true" #productSelection [disabled]="!productSelection.isActive" label="Product Selection">
                    <app-product-selector (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()"></app-product-selector>
                </mat-tab> -->
                
                <mat-tab #uploadDocuments [disabled]="!uploadDocuments.isActive" label="Upload Client Documents">
                    <app-upload-documents (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()">
                    </app-upload-documents>
                </mat-tab>
                <mat-tab #finished [disabled]="!finished.isActive" label="Finished">
                    <app-client-capture-final (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()">
                    </app-client-capture-final>
                </mat-tab>
            </mat-tab-group>

            <!-- <mat-tab-group [(selectedIndex)]="selectedIndex" mat-stretch-tabs>
                <mat-tab #clientDetails label="Client Details">
                    <app-personal-details (updateStepEvent)="updateStep()"></app-personal-details>
                </mat-tab>
                <mat-tab #bankEmployerDetails label="Bank and Employer Details">
                    <app-bank-employer-details (updateStepEvent)="updateStep()">
                    </app-bank-employer-details>
                </mat-tab>
                <mat-tab *ngIf="showProductSelection == true" #creditCheck label="Credit Check Result">
                    <app-credit-check (updateStepEvent)="updateStep()" [event]="clientId" (previousStepEvent)="previousStep()"></app-credit-check>
                </mat-tab>
                <mat-tab *ngIf="showProductSelection == true" #productSelection label="Product Selection">
                    <app-product-selector (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()"></app-product-selector>
                </mat-tab>
                <mat-tab #uploadDocuments label="Upload Client Documents">
                    <app-upload-documents (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()">
                    </app-upload-documents>
                </mat-tab>
                <mat-tab #finished label="Finished">
                    <app-client-capture-final (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()">
                    </app-client-capture-final>
                </mat-tab>
            </mat-tab-group> -->
        </div>
    </div>
</div>